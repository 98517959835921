import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
    ThemeProvider,
    Cell,
} from 'sassafras';

import ComingSoon from './views/ComingSoon';
import Footer from './components/Footer';
import brandTheme from './theme';

import './sass/app.scss';

const App = ({
    brandName,
    publicPath,
    incPath,
    imagePath,
    brandFolder,
}) => {
    const [theme, setTheme] = useState(false);

    useEffect(() => fetch(`/${publicPath}${incPath}${brandFolder}theme.json`)
        .then((response) => response.json())
        .then((data) => {
            setTheme(data);
        }).catch(() => {
            setTheme(brandTheme);
        }), []);

    return (
        <>
            {theme && (
                <ThemeProvider theme={theme}>
                    <ComingSoon
                        brandName={brandName}
                        logo={`${publicPath}${imagePath}${brandFolder}logo.svg`}
                        bgImage={`${publicPath}${imagePath}${brandFolder}bg.svg`}
                    />
                    <Cell area="footer">
                        <Footer
                            brandName={brandName}
                        />
                    </Cell>
                </ThemeProvider>
            )}
        </>
    );
};

App.propTypes = {
    brandName: PropTypes.string,
    publicPath: PropTypes.string,
    incPath: PropTypes.string,
    imagePath: PropTypes.string,
    brandFolder: PropTypes.string,
};

App.defaultProps = {
    brandName: window.brandName || 'AmazingJobs.com',
    publicPath: window.publicPath || 'public/',
    imagePath: window.imagePath || 'images/',
    incPath: window.incPath || 'js/',
    brandFolder: window.brandFolder || '',
};

ReactDOM.render(
    React.createElement(App),
    document.getElementById('app'),
);
