import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
    ThemeContext, Box, Type,
} from 'sassafras';

const styles = createUseStyles({
    Footer: {
        width: '100%',
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },
});

const Footer = ({
    brandName, nav, ...props
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, ...props });

    return (
        <div className={classes.Footer}>
            <Box bgColor="primary800" align="center">
                <Box txtColor="white" width="1200px" padding="md">
                    {nav}
                    <Type tag="p" font="control" align="center">
                        &copy;
                        {' '}
                        { new Date().getFullYear() }
                        {' '}
                        {brandName}
                        , All Rights Reserved.
                    </Type>
                </Box>
            </Box>
        </div>
    );
};

Footer.propTypes = {
    brandName: PropTypes.string,
    nav: PropTypes.node,
    bgColor: PropTypes.string,
};

Footer.defaultProps = {};

export default Footer;
