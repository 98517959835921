import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import {
    Type,
    Box,
} from 'sassafras';

const ComingSoon = ({ bgImage, logo }) => {
    const isMobile = useMediaQuery({
        query: '(max-width: 574px)',
    });

    const font = isMobile ? 'h2' : 'jumbo';
    const margins = isMobile ? ['xs', 0, '5vh', 0] : ['md', 0, '10vh', 0];
    return (
        <Box
            align="center"
            verticalAlign="center"
            bgColor="neutral50"
            bgPos="center bottom"
            bgSize="cover"
            height="calc(100vh - 68px)"
            bgImage={bgImage}
            padding="lg"
        >

            <Box width="240px" margin={margins}>
                <img src={logo} alt="logo" style={{ width: '100%' }} />
            </Box>
            <Box width={400} padding="lg" style={{ border: '4px solid white' }}>
                <Type tag="h1" font={font} align="center" color="white" margin={[0, 0, 'xs', 0]}>
                    COMING SOON
                </Type>
            </Box>
        </Box>
    );
};

ComingSoon.propTypes = {
    bgImage: PropTypes.string,
    logo: PropTypes.string,
};

export default ComingSoon;
