export default {
    colors: {
        primary: {
            process: '#2B73C1',
        },
    },
    typography: {
        company: {
            fontFamily: 'heading',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            textTransform: 'uppercase',
        },
    },
};
